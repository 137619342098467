.side-nav{
  flex: 0 0 auto;
  width: 80px;
  height: 80px;
  position: fixed;
  z-index: 100;

  /*background: #fff;
  border-right: 1px solid #dce0e6;
  position: relative;*/
}

.icon-container{
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.icon-container:hover{
  background: #dfdfdf;
}

.menu-container{
  display: none;
}

.open .menu-container{
  display: block;
  background: white;
  border-right: 1px solid #dce0e6;
  position: fixed;
  width: 300px;
  top: 80px;
  bottom:0;
  left: 0;
}

.side-nav .menu-icon{
  position: absolute;
  top:28px;
  left:28px;
  color: #616063;
}

.side-nav nav{
  width: 100%;
}

.side-nav nav ul{
  flex-direction: column;
  padding:0;
}

.side-nav nav li{
  display: block;
  position: relative;
  width: 100%;
  padding: 0;
}

.side-nav nav li a{
  color:#616063;
  display: flex;
  align-items: center;
  height: 65px;
  padding: 0 0 0 25px;
  border-left: 3px solid #fff;
}

.side-nav nav li a .label {
  font-size: 1rem;
  padding-left:1rem;
}

@media (min-width: 1025px) {
  .side-nav{
    flex: 0 0 auto;
    width: 80px;
    background: #fff;
    border: 1px solid #dce0e6;
    border-width: 1px 1px 1px 0;
    position: fixed;
    height: auto;
  }

  .menu-container{
    display: block;
  }

  .side-nav nav li {
    position: relative;
  }

  .side-nav nav li a .label{
     display: none;
   }

  .side-nav nav li a{
    height: 55px;
  }

  .open.side-nav nav li a .label{
    display: inline;
  }

}
