.chip {
  padding:6px 6px;
  text-align:center;
  border:1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  transition: .3s;
  font-size:16px;
  color: #333;
  margin: 4px 8px 4px 0;
}

.active {
  border: 3px dotted #666;
}
